<template>
  <div class="wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">智能诊断</el-breadcrumb-item>
      <el-breadcrumb-item>风控预警</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content-box">
      <div class="fl-c-b bb mb20">
        <div class="title">预警概览</div>
        <div class="time">更新时间：2020-12-19</div>
      </div>
      <div class="clearfix">
        <div class="fl item" v-for="(item, index) in list" :key="index">
          <div class="fl-c-b bb h51">
            <div>{{ item.name }}</div>
            <i class="el-icon-d-arrow-right"></i>
          </div>
          <v-chart autoresize :options="getOption(item.data)" class="echarts" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          name: '经营变更预警',
          data: [
            { name: '一级预警', value: 1 },
            { name: '二级预警', value: 4 },
            { name: '三级预警', value: 5 },
          ],
        },
        {
          name: '企业迁出预警',
          data: [
            { name: '一级预警', value: 2 },
            { name: '二级预警', value: 10 },
            { name: '三级预警', value: 8 },
          ],
        },
        {
          name: '营风险预警',
          data: [
            { name: '一级预警', value: 3 },
            { name: '二级预警', value: 10 },
            { name: '三级预警', value: 7 },
          ],
        },
        {
          name: '司法风险预警',
          data: [
            { name: '一级预警', value: 4 },
            { name: '二级预警', value: 6 },
            { name: '三级预警', value: 10 },
          ],
        },
        {
          name: '股权风险预警',
          data: [
            { name: '一级预警', value: 5 },
            { name: '二级预警', value: 10 },
            { name: '三级预警', value: 5 },
          ],
        },
        {
          name: '财产风险预警',
          data: [
            { name: '一级预警', value: 6 },
            { name: '二级预警', value: 4 },
            { name: '三级预警', value: 10 },
          ],
        },
      ],
    };
  },
  methods: {
    getOption(data) {
      let total = data.reduce(function (a, b) {
        return a + b.value;
      },0);
      return {
        title: {
          text: total,
          top: 'center',
          left: 'center',
          textStyle: {
            fontSize: 40,
          },
        },
        color: ['#E22132', '#EEB800', '#0E459C'],
        series: [
          {
            type: 'pie',
            radius: ['50%', '65%'],
            data: data,
            label: {
              normal: {
                show: false,
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
          },
        ],
        legend: [
          {
            top: 'center',
            orient: 'vertical',
            left: '72%',
            align: 'left',
            icon: 'circle',
            formatter: (name) => {
              let res = data.filter((v) => v.name === name);
              return '{percent|' + res[0].value + '}\n{name| ' + name + '}';
            },
            data: ['一级预警'],
            textStyle: {
              rich: {
                name: {
                  fontSize: 14,
                  color: '#666',
                  padding: [15, 0, 4, 0],
                },
                percent: {
                  color: '#000',
                  fontWeight: 'bold',
                  fontSize: 16,
                },
              },
            },
          },
          {
            top: 'center',
            orient: 'vertical',
            formatter: (name) => {
              let res = data.filter((v) => v.name === name);
              return '{percent|' + res[0].value + '}\n{name| ' + name + '}';
            },
            right: '72%',
            align: 'right',
            icon: 'circle',
            data: ['二级预警', '三级预警'],
            textStyle: {
              rich: {
                name: {
                  fontSize: 14,
                  color: '#666',
                  padding: [15, 0, 4, 0],
                },
                percent: {
                  color: '#000',
                  fontWeight: 'bold',
                  fontSize: 16,
                },
              },
            },
          },
        ],
      };
    },
  },
  mounted() {},
  created() {},
};
</script>


<style lang='scss' scoped>
.content-box {
  background-color: #fff;
  padding: 0 20px 5px;
}

.title {
  line-height: 74px;
  font-size: 26px;
  color: #333333;
  font-weight: 600;
  padding-left: 14px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 28px;
    left: 0;
    width: 4px;
    height: 20px;
    background: #4393f8;
    border-radius: 3px;
  }
}
.time {
  font-size: 14px;
  color: #666666;
}
.mb20 {
  margin-bottom: 20px;
}
.item {
  width: calc((100% - 32px) / 3);
  margin-right: 16px;
  border: 1px solid #d8d8d8;
  height: 274px;
  margin-bottom: 16px;
  padding: 0 20px;
  font-size: 16px;
  line-height: 15px;
  &:nth-of-type(3n) {
    margin-right: 0;
  }
  .h51 {
    height: 51px;
  }
  .el-icon-d-arrow-right {
    cursor: pointer;
  }
}
.echarts {
  width: 100%;
  height: 222px;
}
</style>